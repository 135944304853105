<template>
  <base-layout-two :page-title="user.fullname" page-default-back-link="/users">
    <!-- Header -->
    <section class="ion-padding background-white">
      <div>
        <span class="h1">
          {{ user.alias ? `${user.alias} (${user.fullname})` : user.fullname }}

          <ion-chip color="primary" class="ion-text-capitalize">
            {{ user.role === "baby" ? "👶" : "👩" }} {{ user.role }}
          </ion-chip>
        </span><br />
        <span class="p">User ID: {{ user.id }}</span>
      </div>

      <div v-if="baby_age">
        <span class="h6">
          {{ baby_age }} old ({{ formatDate(user.profile.birth_date, "MMM do, yyyy") }})
        </span>
      </div>

      <div class="margin-top-4">
        <ion-chip color="danger" class="" v-if="user.profile.pronouns">
          {{ user.profile.pronouns }}
        </ion-chip>

        <ion-chip color="danger" class="" v-if="allergies">
          {{ allergies }}
        </ion-chip>

        <ion-chip color="danger" class="" v-if="dietary_preference">
          {{ dietary_preference }}
        </ion-chip>

        <ion-chip color="primary" class="" v-if="feedingMethod">
          {{ feedingMethod }}
        </ion-chip>

        <ion-chip color="primary" class="" v-if="feedingSource">
          {{ feedingSource }}
        </ion-chip>

        <ion-chip color="primary" class="" v-if="breastfeeding">
          {{ breastfeeding }}
        </ion-chip>

        <ion-chip color="primary" class="" v-if="pumping">
          {{ pumping }}
        </ion-chip>
      </div>

      <div v-if="reservation.arrival_date" class="ion-text-center">
        <h5>Room {{ reservation.room.number }}</h5>
        <reservation-date-display :startDate="reservation.arrival_date" :endDate="reservation.departure_date"
          class="margin-top-16"></reservation-date-display>
      </div>
    </section>

    <!-- Main Section -->
    <section class="padding-8">
      <ion-grid class="ion-no-padding grid-padding">
        <!-- Tasks Section -->
        <ion-row>
          <ion-col>
            <tasks-section :tasks="sortedTasks" @check-task="checkTask"></tasks-section>
          </ion-col>
        </ion-row>

        <!-- Care Rounds Section -->
        <ion-row v-if="false">
          <ion-col>
            <care-rounds-section v-if="false"></care-rounds-section>
          </ion-col>
        </ion-row>

        <ion-row>
          <!-- Relationships -->
          <ion-col size="12" size-lg="6">
            <relationships-section :relations="relations" :user="user"></relationships-section>
          </ion-col>

          <!-- Care Plans -->
          <ion-col size="12" size-lg="6" v-if="user.role === 'mother'">
            <care-plans-section :care_plans="care_plans" :user="user"></care-plans-section>
          </ion-col>

          <!-- Activities Section -->
          <ion-col size-lg="6" size="12">
            <activities-section :activities="careActivities" :user="user"></activities-section>
          </ion-col>

          <!-- Team Notes -->
          <ion-col size-lg="6" size="12">
            <team-notes-section :notes="notes" :user="user"></team-notes-section>
          </ion-col>

          <!-- Profile Section -->
          <ion-col size="12" size-lg="6">
            <profile-section :user="user"></profile-section>
          </ion-col>

          <!-- Resources -->
          <ion-col size="12" size-lg="6" v-if="user.role === 'mother'">
            <resources-section :resources="resources" :user="user"></resources-section>
          </ion-col>

          <!-- EPDS Section -->
          <ion-col size="12" size-lg="6" v-if="user.role === 'mother'">
            <epds-questionnaires-section :epds_questionnaires="epds_questionnaires"
              :user="user"></epds-questionnaires-section>
          </ion-col>

          <!-- Boram Anywhere -->
          <ion-col size="12" size-lg="6" v-if="user.role === 'mother'">
            <continued-care-section :cc_notes="cc_notes" :user="user"></continued-care-section>
          </ion-col>

          <!-- Sales -->
          <ion-col size="12" size-lg="6" v-if="user.role === 'mother' && authUser.role !== 'care_associate'">
            <sales-section :activities="salesActivities" :user="user"></sales-section>
          </ion-col>

          <!-- Reservations -->
          <ion-col size="12" size-lg="6" v-if="user.role === 'mother'">
            <reservations-section :reservations="reservations" :user="user"></reservations-section>
          </ion-col>

          <!-- Payment Methods -->
          <ion-col size="12" size-lg="6" v-if="user.role === 'mother'" v-show="false">
            <payment-methods-section :payment_methods="payment_methods" :user="user">
            </payment-methods-section>
          </ion-col>

          <!-- Billing -->
          <ion-col size="12" size-lg="6" v-if="user.role === 'mother'">
            <folios-section :folios="folios" :user="user"></folios-section>
          </ion-col>
        </ion-row>
      </ion-grid>
    </section>

    <!-- Floating Action Button -->
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button :router-link="`/users/${$route.params.id}/activities/select`">
        <ion-icon :icon="add"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </base-layout-two>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonFab,
  IonFabButton,
  IonIcon,
  IonChip,
} from "@ionic/vue";
import { add } from "ionicons/icons";

import ActivitiesSection from "@/components/users/activities/ActivitiesSection.vue";
import CarePlansSection from "@/components/users/care_plans/CarePlansSection.vue";
import CareRoundsSection from "@/components/users/care_rounds/CareRoundsSection.vue";
import ContinuedCareSection from "@/components/users/continued_care/ContinuedCareSection.vue";
import EpdsQuestionnairesSection from "@/components/users/continued_care/EpdsQuestionnairesSection.vue";
import FoliosSection from "@/components/users/folios/FoliosSection.vue";
import PaymentMethodsSection from "@/components/users/payment_methods/PaymentMethodsSection.vue";
import ProfileSection from "@/components/users/profile/ProfileSection.vue";
import RelationshipsSection from "@/components/users/relationships/RelationshipsSection.vue";
import ReservationsSection from "@/components/users/reservations/ReservationsSection.vue";
import ResourcesSection from "@/components/users/resources/ResourcesSection.vue";
import ReservationDateDisplay from "@/components/reservations/ReservationDateDisplay.vue";
import SalesSection from "@/components/users/sales/SalesSection.vue";
import TasksSection from "@/components/tasks/TasksSection.vue";
import TeamNotesSection from "@/components/users/activities/TeamNotesSection.vue";

import { mapGetters } from "vuex"

import { formatDuration, intervalToDuration } from "date-fns";
import { formatDate } from "@/util/helpers";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonFab,
    IonFabButton,
    IonIcon,
    IonChip,
    ActivitiesSection,
    CareRoundsSection,
    CarePlansSection,
    ContinuedCareSection,
    EpdsQuestionnairesSection,
    FoliosSection,
    PaymentMethodsSection,
    ProfileSection,
    RelationshipsSection,
    ReservationsSection,
    ResourcesSection,
    ReservationDateDisplay,
    SalesSection,
    TasksSection,
    TeamNotesSection,
  },

  data() {
    return {
      add,
      activities: [],
      care_plans: [],
      cc_notes: [],
      epds_questionnaires: [],
      events: [],
      folios: [],
      notes: [],
      payment_methods: [],
      relations: [],
      reservations: [],
      reservation: {},
      resources: [],
      tasks: [],
      sortedTasks: [],
      user: {
        profile: {},
      },
      formatDate,
    };
  },

  computed: {
    ...mapGetters(["authUser"]),

    feedingMethod() {
      if (this.user.profile) {
        if (this.user.profile.baby_feeding_method === "breast")
          return "Breastfeeding only";
        else if (this.user.profile.baby_feeding_method === "bottle")
          return "Bottle only";
        else if (this.user.profile.baby_feeding_method === "combination")
          return "Breast and Bottle";
      }

      return "";
    },

    feedingSource() {
      if (this.user.profile) {
        if (this.user.profile.baby_feeding_source === "breastmilk")
          return "Breastmilk only";
        else if (this.user.profile.baby_feeding_source === "formula")
          return "Formula only";
        else if (this.user.profile.baby_feeding_source === "combination")
          return "Breastmilk and Formula";
      }

      return "";
    },

    breastfeeding() {
      if (this.care_plans.length > 0) {
        let latestCarePlan = this.care_plans[0];

        if (latestCarePlan.breast_feeding) return "Breastfeeding";
      }

      return null;
    },

    pumping() {
      if (this.care_plans.length > 0) {
        let latestCarePlan = this.care_plans[0];

        if (latestCarePlan.pumping) return "Pumping";
      }

      return null;
    },

    allergies() {
      if (this.user.profile.allergies) {
        return "🤧 Has allergies";
      }

      return null;
    },

    dietary_preference() {
      if (this.user.profile.dietary_preferences === "gluten-free")
        return "🚫🍞 Gluten-Free";
      else if (this.user.profile.dietary_preferences === "vegetarian")
        return "🥦 Vegetarian";
      else if (this.user.profile.dietary_preferences === "kosher")
        return "Kosher";
      return null;
    },

    baby_age() {
      if (this.user.profile.birth_date) {
        let duration = intervalToDuration({
          start: new Date(this.user.profile.birth_date),
          end: new Date(),
        });

        return formatDuration(duration, {
          format: ["years", "months", "weeks", "days"],
          delimiter: ", ",
        });
      }
      return null;
    },

    salesActivities() {
      let salesTypes = ["sales_info_session_zoom", "sales_ca_call_zoom", "sales_tour_onsite", "sales_followup", "sales_reservation"]

      return this.activities.filter((x) => { return (salesTypes.includes(x.type)) ? true : false })
    },

    careActivities() {
      let salesTypes = ["sales_info_session_zoom", "sales_ca_call_zoom", "sales_tour_onsite", "sales_followup", "sales_reservation"]

      return this.activities.filter((x) => { return (salesTypes.includes(x.type)) ? false : true })
    }
  },

  watch: {
    tasks() {
      this.sortedTasks = this.tasks.sort((a, b) => {
        if (a.status > b.status) return 1;
        if (a.status < b.status) return -1;

        if (new Date(a.due_date) > new Date(b.due_date)) return -1;
        if (new Date(a.due_date) < new Date(b.due_date)) return 1;

        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
      });
    },
  },

  async ionViewDidEnter() {
    await this.fetchUser();
    this.fetchActivities();
    this.fetchTasks();
    this.fetchNotes();
    this.fetchFolios();
    this.fetchRelations();
    this.fetchCarePlans();
    this.fetchReservations();
    this.fetchResources();
    this.fetchEpdsQuestionnaires();
    this.fetchCcNotes();
    // this.fetchPaymentMethods();
  },

  ionViewDidLeave() {
    this.tasks = [];
  },

  methods: {
    /**
     * Fetch User
     */
    async fetchUser() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/users/${this.$route.params.id}`)
        .then((response) => { this.user = response.data.user })
        .catch((error) => { console.log(error) });
    },


    /**
     * Fetch Tasks
     */
    async fetchTasks() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/tasks/?user_id=${this.$route.params.id}`)
        .then((response) => { this.tasks = response.data.tasks })
        .catch((error) => { console.log(error) });
    },


    /**
     * Fetch Folios
     */
    async fetchFolios() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/folios?user_id=${this.$route.params.id}`)
        .then((response) => {
          // Sort in descending order
          this.folios = response.data.folios.sort((a, b) => { return b.id - a.id; });
        })
        .catch((error) => { console.log(error) });
    },


    /**
     * Fetch Notes
     */
    async fetchNotes() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/activities?user_id=${this.$route.params.id}&type=note`)
        .then((response) => {
          this.notes = response.data.activities.sort((a, b) => {
            return new Date(b.datetime) - new Date(a.datetime);
          });
        })
        .catch((error) => { console.log(error) });
    },


    /**
     * Fetch Activities
     */
    async fetchActivities() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/activities?user_id=${this.$route.params.id}`)
        .then((response) => {
          this.activities = response.data.activities.sort((a, b) => {
            return new Date(b.datetime) - new Date(a.datetime);
          });
        })
        .catch((error) => { console.log(error) });
    },


    /**
     * Fetch Relations
     */
    async fetchRelations() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/relations?user_id=${this.$route.params.id}`)
        .then((response) => { this.relations = response.data.relations })
        .catch((error) => { console.log(error) });
    },


    /**
     * Fetch Care Plans
     */
    async fetchCarePlans() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/care_plans?mother_id=${this.$route.params.id}`)
        .then((response) => {
          this.care_plans = response.data.care_plans.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
          });
        })
        .catch((error) => { console.log(error) });
    },


    /**
     * Fetch Reservations
     */
    async fetchReservations() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/reservations?user_id=${this.$route.params.id}`)
        .then((response) => {
          this.reservations = response.data.reservations.sort((a, b) => {
            return b.id - a.id;
          });

          // Set the current reservation to their latest reservation
          if (this.reservations.length > 0)
            this.reservation = this.reservations[0];
        })
        .catch((error) => { console.log(error) });
    },


    /** Fetch Resources */
    async fetchResources() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/resources?user_id=${this.$route.params.id}`)
        .then((response) => { this.resources = response.data.resources })
        .catch((error) => { console.log(error) });
    },


    /**
     * Fetch Payment Methods
     */
    async fetchPaymentMethods() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/transactions/stripe/paymentmethods?user_id=${this.$route.params.id}`)
        .then((response) => { this.payment_methods = response.data.payment_methods.data })
        .catch((error) => { console.log(error) });
    },


    /** Fetch EPDS */
    async fetchEpdsQuestionnaires() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/epds_questionnaires/?user_id=${this.$route.params.id}`)
        .then((response) => { this.epds_questionnaires = response.data.epds_questionnaires })
        .catch((error) => { console.log(error) });
    },


    /**
     * Fetch CcNotes
     */
    async fetchCcNotes() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/activities?user_id=${this.$route.params.id}&type=cc_note`)
        .then((response) => {
          this.cc_notes = response.data.activities.sort((a, b) => {
            return new Date(b.datetime) - new Date(a.datetime);
          });
        })
        .catch((error) => { console.log(error) });
    },


    /**
     * Check a Task
     * 
     * @param {Object} task 
     */
    async checkTask(task) {
      if (task.status) task.status = false;
      else task.status = true;

      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/tasks/${task.id}`,
        data: task,
      };

      await this.axios(config)
        .then(() => {
          this.fetchTasks();
        })
        .catch((error) => { console.log(error) });
    },
  },
};
</script>