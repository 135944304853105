<template>
  <base-card
    :title="`🏨 Reservations`"
    link-text="See All"
    :link-url="`/users/${user.id}/reservations`"
  >
    <ion-list>
      <reservation-list-item
        v-for="reservation in reservations.slice(0, 1)"
        v-bind:key="reservation.id"
        :reservation="reservation"
        lines="none"
        :show-notes="true"
      ></reservation-list-item>
    </ion-list>

    <ion-button
      fill="outline"
      class="ion-margin-top"
      :router-link="`/reservations/create?user_id=${this.user.id}`"
      v-if="authUser.role === 'admin' || authUser.role === 'manager'"
    >
      New Reservation
    </ion-button>
  </base-card>
</template>

<script>
import { IonList, IonButton } from "@ionic/vue"
import ReservationListItem from "@/components/reservations/ReservationListItem.vue";
import { mapGetters } from "vuex"

export default {
  props: {
    reservations: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonButton,
    ReservationListItem,
  },

  computed: {
    ...mapGetters(["authUser"]),
  },
};
</script>