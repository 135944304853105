<template>
  <base-card :title="`📝 Resources for Guest (${resources.length})`" link-text="See All" :link-url="`/users/${user.id}/resources`">
    <ion-list>
      <resource-list-item v-for="resource in resources.slice(0, 2)" v-bind:key="resource.id" :resource="resource" :router-link="`/resources/${resource.id}`"
        lines="full">
      </resource-list-item>
    </ion-list>

    <ion-button fill="outline" class="ion-margin-top" :router-link="`/users/${user.id}/resources`">
      Highlight Resources
    </ion-button>
  </base-card>
</template>

<script>
import { IonList, IonButton } from "@ionic/vue";
import ResourceListItem from "@/components/resources/ResourceListItem";

export default {
  props: {
    resources: {
      type: Array,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonButton,
    ResourceListItem,
  },
};
</script>