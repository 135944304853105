<template>
  <base-card
    :title="`📱 Boram Anywhere (${cc_notes.length})`"
    link-text="See All"
    :link-url="`/users/${user.id}/continued_care/cc_notes`"
  >
    <ion-list>
      <continued-care-note-list-item
        v-for="cc_note in cc_notes.slice(0,3)"
        :key="cc_note.id"
        :cc_note="cc_note">
      </continued-care-note-list-item>
    </ion-list>

    <ion-button
      fill="outline"
      class="ion-margin-top"
      :router-link="`/users/${user.id}/continued_care/cc_notes/create`"
    >
      New Boram Anywhere Note
    </ion-button>
  </base-card>
</template>

<script>
import { IonList, IonButton } from "@ionic/vue"
import ContinuedCareNoteListItem from "@/components/users/continued_care/ContinuedCareNoteListItem.vue";
import { mapGetters } from "vuex"

export default {
  props: {
    cc_notes: {
      type: Array,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonButton,
    ContinuedCareNoteListItem
  },

  computed: {
    ...mapGetters(["authUser"]),
  },
};
</script>