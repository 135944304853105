<template>
  <base-card
    :title="`✏️ Team Notes (${notes.length})`"
    link-text="See All"
    :link-url="`/users/${user.id}/activities/notes`"
  >
    <team-note-card
      v-for="note in notes.slice(0, 3)"
      :key="note.id"
      :note="note"
    ></team-note-card>

    <ion-button
      fill="outline"
      :router-link="`/users/${user.id}/activities/notes/create`"
      class="ion-margin-top"
    >
      Add Team Note
    </ion-button>
  </base-card>
</template>

<script>
import { IonButton } from "@ionic/vue"
import TeamNoteCard from "@/components/users/activities/TeamNoteCard.vue";

export default {
  props: {
    notes: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },

  components:{
    IonButton,
    TeamNoteCard,
  }
};
</script>