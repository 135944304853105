<template>
  <ion-item :lines="lines" class="ion-no-padding">
    <ion-label class="ion-text-wrap">
      {{ formatDate(care_plan.date, "iiii - MMMM do, yyyy") }}
    </ion-label>
  </ion-item>
</template>

<script>
import { IonItem, IonLabel } from "@ionic/vue";
import { formatDate } from "@/util/helpers"
import { format } from "date-fns"

export default {
  props: {
    care_plan: {
      type: Object,
      required: true,
    },
    lines: {
      type: String,
      default: "full",
    },
  },

  components: {
    IonItem,
    IonLabel,
  },

  computed: {},

  data() {
    return {
      formatDate,
      format
    };
  },
};
</script>

<style scoped>
ion-item {
  --padding-bottom: 8px;
  --padding-top: 8px;
  --border-color: var(--light-line-color);
}

.ion-text-wrap {
  font-size: 16px;
}
</style>