<template>
  <ion-item :lines="lines" class="ion-no-padding">
    <ion-label>
      <div class="h6">
        {{ formatDate(epds_questionnaire.created_at, 'MMM do, yyyy') }}
        <p>Total: {{ epds_questionnaire.score_total }}</p>
      </div>
    </ion-label>
    <ion-label slot="end" class="ion-text-right ion-no-margin">
      <p>Anxiety: {{ epds_questionnaire.score_anxiety }}</p>
      <p>SI: {{ epds_questionnaire.score_si }}</p>
    </ion-label>
  </ion-item>
</template>

<script>
import { IonItem, IonLabel } from "@ionic/vue";
import { formatDate } from "@/util/helpers";

export default {
  props: {
    epds_questionnaire: {
      type: Object,
      required: true,
    },
    lines: {
      type: String,
      default: "full",
    },
  },

  components: {
    IonItem,
    IonLabel,
  },

  data(){
    return{
      formatDate
    }
  }
};
</script>

<style scoped>
ion-item {
  --padding-bottom: 8px;
  --padding-top: 8px;
  --border-color: var(--light-line-color);
}
</style>