<template>
  <base-section title="Daily Care Rounds">
    <ion-grid>
      <ion-row>
        <ion-col size="12" size-lg="6">
          <h4>Morning</h4>
          <ion-item lines="none" class="ion-no-padding">
            <ion-checkbox :checked="true"></ion-checkbox>
            <ion-label>Sitz Bath</ion-label>
          </ion-item>
          <ion-item lines="none" class="ion-no-padding">
            <ion-checkbox :checked="false"></ion-checkbox>
            <ion-label>Foot Bath</ion-label>
          </ion-item>
          <ion-item lines="none" class="ion-no-padding">
            <ion-checkbox :checked="false"></ion-checkbox>
            <ion-label>Breast Massage</ion-label>
          </ion-item>
        </ion-col>
        <ion-col size="12" size-lg="6">
          <h4>Evening</h4>
          <ion-item lines="none" class="ion-no-padding">
            <ion-checkbox :checked="true"></ion-checkbox>
            <ion-label>Sitz Bath</ion-label>
          </ion-item>
          <ion-item lines="none" class="ion-no-padding">
            <ion-checkbox :checked="false"></ion-checkbox>
            <ion-label>Foot Bath</ion-label>
          </ion-item>
          <ion-item lines="none" class="ion-no-padding">
            <ion-checkbox :checked="true"></ion-checkbox>
            <ion-label>Breast Massage</ion-label>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>
  </base-section>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonCheckbox,
} from "@ionic/vue";

export default {
  props: {
  },

  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonCheckbox,
  },
};
</script>