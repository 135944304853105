<template>
  <base-card title="🫶 Relationships">
    <ion-list>
      <user-preview-card
        v-for="relation in relations"
        :key="relation.id"
        :user="relatedUser(relation)"
        class="ion-margin-top ion-margin-bottom"
        :router-link="`/users/${relatedUser(relation).id}/`"
        lines="full"
      ></user-preview-card>
    </ion-list>

    <ion-button
      v-if="user.role === 'mother'"
      fill="outline"
      class="ion-margin-top"
      :router-link="`/users/create?type=guest&role=baby&relation_from_id=${$route.params.id}`"
    >
      Add Baby
    </ion-button>
  </base-card>
</template>

<script>
import { IonList, IonButton } from "@ionic/vue";
import UserPreviewCard from "@/components/users/UserPreviewCard.vue";

export default {
  props: {
    relations: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonButton,
    UserPreviewCard,
  },

  methods: {
    relatedUser(relation){
      // If the relation is defined with FROM as this user, return the User TO
      if(relation.user_id_from === this.user.id){
        return relation.user_to
      }

      // Else return the User FORM
      else{
        return relation.user_from
      } 
    }
  }
};
</script>