<template>
  <base-card :title="`🤝 Sales (${activities.length})`" link-text="See All"
    :link-url="`/users/${user.id}/activities?type=sales`">
    <activity-list-item v-for="activity in activities.slice(0, 3)" :key="activity.id" :activity="activity"
      :router-link="`/users/${user.id}/activities/${activity.id}`" lines="none"></activity-list-item>

    <ion-button fill="outline" :router-link="`/users/${user.id}/activities/select`" class="ion-margin-top">
      Add Activity
    </ion-button>
  </base-card>
</template>

<script>
import { IonButton } from "@ionic/vue"
import ActivityListItem from "@/components/users/activities/ActivityListItem.vue";

export default {
  props: {
    activities: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
  },

  components: {
    IonButton,
    ActivityListItem
  }
};
</script>