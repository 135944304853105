<template>
  <base-card
    title="🧘‍♀️ Daily Care Plans"
    link-text="See All"
  >
    <care-plan-list-item
      v-for="care_plan in care_plans"
      :key="care_plan.id"
      :care_plan="care_plan"
      :router-link="`/users/${user.id}/care_plans/${care_plan.id}`"
    >
    </care-plan-list-item>

    <ion-button
      fill="outline"
      class="ion-margin-top"
      :router-link="`/users/${user.id}/care_plans/create`"
    >
      Add Care Plan
    </ion-button>
  </base-card>
</template>

<script>
import { IonButton } from "@ionic/vue";
import CarePlanListItem from "@/components/users/care_plans/CarePlanListItem.vue";

export default {
  props: {
    care_plans: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonButton,
    CarePlanListItem,
  },
};
</script>