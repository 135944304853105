<template>
  <base-card
    :title="`📕 EPDS`"
  >
    <ion-list>
      <epds-questionnaire-list-item
        v-for="epds_questionnaire in epds_questionnaires"
        v-bind:key="epds_questionnaire.id"
        :epds_questionnaire="epds_questionnaire"
        :router-link="`/users/${user.id}/continued_care/epds_questionnaires/${epds_questionnaire.id}`"
        lines="full"
      ></epds-questionnaire-list-item>
    </ion-list>
  </base-card>
</template>

<script>
import { IonList } from "@ionic/vue"
import EpdsQuestionnaireListItem from "@/components/users/continued_care/EpdsQuestionnaireListItem.vue";
import { mapGetters } from "vuex"

export default {
  props: {
    epds_questionnaires: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    EpdsQuestionnaireListItem,
  },

  computed: {
    ...mapGetters(["authUser"]),
  },
};
</script>