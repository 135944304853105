<template>
  <base-card :title="`Payment Methods`">
    <ion-list>
      <ion-item
        v-for="payment_method in payment_methods"
        :key="payment_method.id"
        class="ion-no-padding"
        lines="none"
        :router-link="`/users/${user.id}/payment_methods/1`"
      >
        <ion-label>
          <span class="ion-text-uppercase">{{ payment_method.card.brand }}</span
          ><br />
          Card ending in **** {{ payment_method.card.last4 }}
        </ion-label>
      </ion-item>
    </ion-list>

    <ion-button
      fill="outline"
      class="ion-margin-top"
      :router-link="`/users/${$route.params.id}/payment_methods/create`"
    >
      New Payment Method
    </ion-button>
  </base-card>
</template>

<script>
import { IonList, IonItem, IonLabel, IonButton } from "@ionic/vue";

export default {
  props: {
    payment_methods: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonItem,
    IonLabel,
    IonButton,
  },
};
</script>