<template>
  <base-card
    :title="`💳 Billing`"
    link-text="See All"
    :link-url="`/users/${user.id}/folios`"
  >
    <ion-list>
      <folio-list-item
        v-for="folio in folios.slice(0, 4)"
        v-bind:key="folio.id"
        :folio="folio"
        :router-link="`/users/${user.id}/folios/${folio.id}`"
        lines="full"
      >
      </folio-list-item>
    </ion-list>

    <ion-button
      fill="outline"
      class="ion-margin-top"
      :router-link="`/users/${user.id}/folios/create`"
    >
      Add Order
    </ion-button>
  </base-card>
</template>

<script>
import { IonList, IonButton } from "@ionic/vue";
import FolioListItem from "@/components/users/folios/FolioListItem.vue";

export default {
  props: {
    folios: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonButton,
    FolioListItem,
  },
};
</script>