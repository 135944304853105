<template>
  <base-card title="🔔 Tasks">
    <ion-accordion-group value="todo">
      <ion-accordion value="todo">
        <ion-item slot="header" class="ion-no-padding">
          <h4>To Do ({{ activeTasks.length }})</h4>
        </ion-item>

        <ion-list slot="content">
          <task-list-item v-for="task in activeTasks" :key="task.id" :router-link="`/tasks/${task.id}`" :task="task"
            @check-task="$emit('check-task', task)">
          </task-list-item>
        </ion-list>
      </ion-accordion>

      <ion-accordion value="done">
        <ion-item slot="header" class="ion-no-padding">
          <h4>Done ({{ doneTasks.length }})</h4>
        </ion-item>

        <ion-list slot="content">
          <task-list-item v-for="task in doneTasks" :key="task.id" :router-link="`/tasks/${task.id}`" :task="task"
            @check-task="$emit('check-task', task)">
          </task-list-item>
        </ion-list>
      </ion-accordion>
    </ion-accordion-group>

    <ion-button fill="outline" class="margin-top-16" :router-link="`/tasks/create?user_id=${userId}`">
      Add Task
    </ion-button>
  </base-card>
</template>

<script>
import { IonAccordionGroup, IonAccordion, IonList, IonItem, IonButton } from "@ionic/vue";
import TaskListItem from "@/components/tasks/TaskListItem.vue";

export default {
  props: {
    title: {
      Object: String
    },
    tasks: {
      Object: Array,
      required: true,
    },
  },

  components: {
    IonAccordionGroup,
    IonAccordion,
    TaskListItem,
    IonList,
    IonItem,
    IonButton
  },

  data() {
    return {};
  },

  computed: {
    activeTasks() {
      return this.tasks.filter((x) => x.status === 0)
    },

    doneTasks() {
      return this.tasks.filter((x) => x.status === 1)
    },

    userId() {
      return this.$route.params.id
    },
  }
};
</script>