<template>
  <base-card :title="`⭐️ Profile`" link-text="See All" :link-url="`/users/${user.id}/profile`">
    <!-- Mother Profile -->
    <section v-if="user.role === 'mother'">
      <!-- Email -->
      <base-field-display label-text="Email" lines="none">
        <p>
          {{ user.email ? user.email : "" }}
        </p>
      </base-field-display>

      <!-- Support Person Contact -->
      <base-field-display label-text="Support Person" lines="none">
        <p>
          {{
            user.profile.support_person_name
            ? user.profile.support_person_name
            : ""
          }}
        </p>
      </base-field-display>

      <!-- Allergies -->
      <base-field-display label-text="Allergies" lines="none">
        <p>
          {{ user.profile.allergies ? user.profile.allergies : "" }}
        </p>
      </base-field-display>

      <!-- Dietary Preferences -->
      <base-field-display label-text="Dietary Preferences" lines="none">
        <p>
          {{ user.profile.dietary_preferences ? user.profile.dietary_preferences : "" }}
        </p>
      </base-field-display>

      <!-- Due Date -->
      <base-field-display label-text="Due Date" lines="none">
        <p>
          {{ user.profile.due_date ? formatDate(user.profile.due_date, "MMM do, yyyy") : "" }}
        </p>
      </base-field-display>
    </section>

    <!-- Baby Profile -->
    <section v-if="user.role === 'baby'">
      <base-field-display label-text="Birth Date" lines="none">
        <p v-if="user.profile && user.profile.birth_date">
          {{ formatDate(user.profile.birth_date, "MMM do, yyyy") }}
        </p>
      </base-field-display>
    </section>
  </base-card>
</template>

<script>
import { formatDate } from "@/util/helpers"

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      formatDate,
    };
  },
};
</script>